<template>
    <section class="wrapper">
        <banner :message="message"
                :message-type="messageType"
                :is-shown="isBannerShown"
                :banner-close="onBannerClose"
        />
        <nav-bar />
        <form class="userForm" @submit="createUserImpl">
            <section class="userForm__header">
                <h1 class="userForm__title">
                    Create
                </h1>
                <p class="userForm__subtitle">
                    Fill the form below to create a user
                </p>
            </section>
            <section class="userForm__content userForm__content--left">
                <div class="form__control">
                    <label class="form__label">Name</label>
                    <input v-model.trim="name"
                           type="text"
                           class="form__input"
                           autofocus
                           placeholder="Example: David"
                    >
                </div>
                <div class="form__control">
                    <label class="form__label">Email</label>
                    <input v-model.trim="email"
                           type="email"
                           class="form__input"
                           autofocus
                           placeholder="Example: David@mail.com"
                    >
                </div>

            </section>
            <section class="userForm__content userForm__content--right">
                <div class="form__control">
                    <label class="form__label">Password</label>
                    <input v-model.trim="password"
                           type="password"
                           class="form__input"
                           autofocus
                           placeholder="Example: Password"
                    >
                </div>
            </section>
            <section class="userForm__actions">
                <button class="button left"
                        type="button"
                        @click="goBack"
                >
                    <font-awesome-icon icon="arrow-left" class="mr-2" />
                    Back
                </button>
                <button class="button right" type="submit">
                    <font-awesome-icon icon="save" class="mr-2" />
                    Create
                </button>
            </section>
        </form>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import NavBar from '@/components/ui/Navbar.vue';
import Banner from '@/components/ui/Banner.vue';

export default {
    components: {
        NavBar,
        Banner,
    },

    data() {
        return {
            message: '',
            messageType: '',
            isBannerShown: false,
            name: '',
            email: '',
            password: '',
        };
    },

    methods: {
        ...mapActions('auth', ['signUp']),

        async createUserImpl() {
            if (!this.name || !this.email || !this.password) {
                this.renderBanner('error', 'Email, Name and Password are required');
                return;
            }
            await this.signUp({
                email: this.email,
                name: this.name,
                password: this.password,
            });
        },

        renderBanner(type, message) {
            this.messageType = type;
            this.message = message;
            this.isBannerShown = true;
            setTimeout(() => { this.isBannerShown = false; }, 3000);
        },

        goBack(e) {
            e.preventDefault();
            this.$router.push({ path: '/home' });
        },

        onBannerClose() {
            this.isBannerShown = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply w-full;
    @apply flex;
    min-height: 100vh;
}

.userForm{
    @apply relative;
    @apply h-auto;
    @apply mt-16;
    @apply mx-auto;
    width: 70%;
    @apply grid;
    @apply gap-6;
    @apply p-12;
    grid-template-columns: repeat(50%);
    grid-template-rows: 5rem 1fr 3rem;
    grid-template-areas:
        "header header"
        "contentLeft contentRight"
        "actions actions";
}
.userForm__content{
    &--left{
        grid-area: contentLeft;
    }
    &--right{
        grid-area: contentRight;
    }
}
.userForm__actions {
    grid-area: actions;
    @apply flex;
    @apply items-center;
    @apply justify-end;
}

.userForm__title {
    @apply text-2xl;
    @apply font-semibold;
    @apply text-gray-900;
}

.userForm__subtitle {
    @apply text-base;
    @apply font-medium;
    @apply text-gray-700;
}

.button {
    @apply px-5;
    @apply py-2;
    @apply rounded-md;
    @apply shadow-sm;
    @apply h-12;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply bg-white;
    @apply text-gray-700;
    @apply text-base;
    @apply font-medium;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply mx-2;
    &.left {
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply bg-gray-100;
        &:hover,&:focus {
            @apply text-gray-900;
        }
    }
    &.right {
       @apply bg-accent;
       @apply text-white;
    }
}
</style>
